/* eslint-disable no-console */

import { RefObject, useEffect, useId, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { EventListener } from '@/lib/util/eventListener';
import { captureEvent } from '@/lib/util/captureEvent';



/* #region  Types */

export type AdUnitType = 
  'leaderboard_atf' |
  'leaderboard_btf' |
  'med_rect_atf' | 
  'med_rect_btf' |
  'corner_ad_video' | 
  'precontent_ad_video' | 
  'standard_iab';

export type AdUnit = { 
  type: AdUnitType
  selectorId?: string 
}

declare global {
  interface Window {
    Bolt?: {
      on: (unit: string, eventName: string, callback: () => void) => void
      BOLT_AD_COMPLETE: string
      BOLT_AD_ERROR: string
      BOLT_AD_REQUEST_START: string
    }
    PageOS?: {
      session?: {
        newPageView: () => void
      }
    }
    ramp?: {
      que: any[]
      passiveMode: boolean
      settings: {
        slots: {
          [key: string]: {
            id: string
          }
        }
      }
      addUnits: (units: AdUnit[]) => Promise<void>
      destroyUnits: (units: AdUnitType[] | string) => Promise<void>
      displayUnits: () => Promise<void>
      getUnits: () => string[]
      showAdSlot: (slotId: string) => void
      hideAdSlot: (slotId: string) => void
      hideSlots: (slotIds: string[]) => void
      triggerRefresh: () => void
      onPlayerReady: () => void
      setPath: (path: string) => void
    }
    __playwireBoltReady?: boolean
    __playwireRampReady?: boolean
  }
}

/* #endregion */



/* #region  Playwire Event Bus */

export const playwireEvents = new EventListener<{
  precontentRequestStart: () => void
  precontentComplete: () => void
}>();

/* #endregion */



/* #region Hooks */

export function usePlaywirePrecontentVideo () {
  return async function showPlaywirePrecontent (callback: () => void) {
    const onComplete = () => {
      playwireEvents.offAll('precontentRequestStart');
      playwireEvents.offAll('precontentComplete');
      callback();
    };

    (async () => {
      try {
        await waitForPlaywirePlayer();
      } catch (err) {
        console.error('[PLAYWIRE] Pre-Content Video Error:', err);
        return onComplete();
      }

      try {
        await window.ramp.addUnits([ { type: 'precontent_ad_video' } ]);
        window.ramp.displayUnits();
            
        // If the video player fails to initialize within 5 seconds, assume it
        // won't load and resolve the preroll.
        const playerTimeout = setTimeout(onComplete, 5000);

        playwireEvents.offAll('precontentRequestStart');
        playwireEvents.on('precontentRequestStart', () => { 
          clearTimeout(playerTimeout);
          captureEvent('ad_impression', { 
            tag_name: 'ad_impression_request',
            ad_unit: 'precontent_ad_video',
          });
        });

        playwireEvents.offAll('precontentComplete');
        playwireEvents.on('precontentComplete', () => {
          clearTimeout(playerTimeout);
          onComplete();
          captureEvent('ad_impression', { 
            tag_name: 'ad_impression_display',
            ad_unit: 'precontent_ad_video',
          });
        });
      } catch (err) {
        console.error('[PLAYWIRE] Pre-Content Video Error:', err);
        onComplete();
      }
    })();
  };
}

export function usePlaywireAdUnit (ref: RefObject<HTMLElement>, unit: AdUnit) {  
  const observerRef = useRef<IntersectionObserver | null>(null);
  const componentWillUnmountRef = useRef(false);

  useEffect(() => {
    return () => {
      componentWillUnmountRef.current = true;
    };
  }, []);

  useEffect(() => {
    const containerEl = ref.current;

    const observer = observerRef.current = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          const parentVisible = !!(entry.target as HTMLDivElement)?.offsetParent;
          const partiallyVisible = entry.isIntersecting;

          if (parentVisible && partiallyVisible) {
            displayAdUnit(containerEl, unit);
          } else {
            hideAdUnit(containerEl);
          }
        }
      }, 
      { threshold: 0.01 }
    );

    if (containerEl) {
      observer.observe(containerEl);
    }

    displayAdUnit(containerEl, unit);

    return () => {
      if (containerEl) {
        observer.unobserve(containerEl);
      }
      observer.disconnect();

      if (componentWillUnmountRef.current) {
        destroyAdUnit(containerEl);
      }
    };
  }, [ unit, ref ]);
}

export interface PlaywireCornerVideoOptions {
  breakpointVisibleAt: number
}

export function usePlaywireCornerVideo (options?: PlaywireCornerVideoOptions) {  
  const componentWillUnmountRef = useRef(false);
  const debounceRef = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      componentWillUnmountRef.current = true;
    };
  }, []);

  useEffect(() => {
    const onResize = () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }

      debounceRef.current = setTimeout(() => {
        if (
          (options?.breakpointVisibleAt && window.innerWidth >= options.breakpointVisibleAt) ||
          !options?.breakpointVisibleAt
        ) {
          displayCornerVideo();
        } else {
          destroyCornerVideo();
        }
      }, 200) as any;
    };
    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      if (componentWillUnmountRef.current) {
        destroyCornerVideo();
      }

      window.removeEventListener('resize', onResize);
    };
  }, [ options ]);
}

export function usePlaywireIdRef () {
  const idRef = useRef('playwire__' + useId());

  return idRef;
}

/* #endregion */



/* #region Utilities */

function waitForPlaywire (): Promise<void> {
  let numTries = 50;

  return new Promise(async (ready, reject) => {
    while (!window.__playwireRampReady) {
      if (numTries <= 0) return reject('Ramp failed to load in time.');
      numTries--;
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    ready();
  });
}

function waitForPlaywirePlayer (): Promise<void> {
  let numTries = 50;

  return new Promise(async (ready, reject) => {
    while (!window.__playwireBoltReady) {
      if (numTries <= 0) return reject('Bolt Player failed to load in time.');
      numTries--;
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    ready();
  });
}

async function displayAdUnit (containerEl: HTMLElement, unit: AdUnit) {
  if (!containerEl) return;
  if (!containerEl.offsetParent) return;

  const rect = containerEl.getBoundingClientRect();
  if (rect.top > window.innerHeight || rect.bottom < 0) return;

  try { await waitForPlaywire(); } 
  catch { return; }

  const adEl = containerEl.querySelector('.pw-tag');
  
  if (adEl) {
    // @ts-ignore
    const adDisplay = adEl.computedStyleMap().get('display').value; // eslint-disable-line
    if (adDisplay !== 'none') return;
    const adId = adEl.getAttribute('id');
    window.ramp.showAdSlot(adId);
  } else {
    await window.ramp.addUnits([ unit ]);
    await window.ramp.displayUnits();
  }

  captureEvent('ad_impression', { 
    tag_name: 'ad_impression_request',
    ad_unit: unit,
  });
}

async function hideAdUnit (containerEl: HTMLElement) {
  if (!containerEl) return;

  try { await waitForPlaywire(); } 
  catch { return; }

  const adEl = containerEl.querySelector('.pw-tag');
  if (!adEl) return;
  
  const adId = adEl.getAttribute('id');
  window.ramp.hideAdSlot(adId);
}

async function destroyAdUnit (containerEl: HTMLElement) {
  if (!containerEl) return;
  
  try { await waitForPlaywire(); } 
  catch { return; }

  const adEl = containerEl.querySelector('.pw-tag');
  if (!adEl) return;
  
  const adId = adEl.getAttribute('id');
  await window.ramp.destroyUnits(adId);
}

export async function destroyAllAdUnits () {
  try { await waitForPlaywire(); } 
  catch { return; }

  window.ramp.destroyUnits('all');
}

async function displayCornerVideo () {
  console.log('[PLAYWIRE] Try display corner video');

  try { await waitForPlaywire(); } 
  catch { return; }
  
  const units = window.ramp.getUnits();
  if (units.includes('corner_ad_video')) return;

  await window.ramp.addUnits([ { type: 'corner_ad_video' } ]);
  await window.ramp.displayUnits();

  captureEvent('ad_impression', { 
    tag_name: 'ad_impression_request',
    ad_unit: 'corner_ad_video',
  });

  console.log('[PLAYWIRE] Corner video displayed');
}

async function destroyCornerVideo () {
  console.log('[PLAYWIRE] Try destroy corner video');

  try { await waitForPlaywire(); } 
  catch { return; }

  await window.ramp.destroyUnits('corner_ad_video');

  console.log('[PLAYWIRE] Corner video destroyed');
}

/* #endregion */



/* #region Playwire Component */

interface RampProps {
  PUB_ID: string
  WEBSITE_ID: string
}

export function Playwire ({ PUB_ID, WEBSITE_ID }: RampProps) {
  const router = useRouter();
  const lastPathRef = useRef(router.asPath);
  const resizeDebounceRef = useRef<number | null>(null);
  const [ rampComponentLoaded, setRampComponentLoaded ] = useState(false);

  useEffect(() => {
    if (!PUB_ID || !WEBSITE_ID) {
      throw new Error('Missing Publisher ID or Website ID');
    }

    const authState = localStorage.getItem('.storage.auth') 
      ? JSON.parse(localStorage.getItem('.storage.auth') as string) 
      : {};

    if (authState?.user?.isPremiumUser) {
      setRampComponentLoaded(true);
      return;
    }

    if (rampComponentLoaded) {
      if (lastPathRef.current !== router.asPath) {
        lastPathRef.current = router.asPath;
        (async () => {
          try {
            await waitForPlaywire();
            window.ramp.que.push(() => {
              window.ramp.triggerRefresh();
              window.PageOS.session.newPageView();
            });
          } catch {}
        })();
      }
      return;
    }

    setRampComponentLoaded(true);
    
    // Playwire setup
    window.ramp = window.ramp || {} as Window[ 'ramp' ];
    window.ramp.que = window.ramp.que || [];
    window.ramp.passiveMode = true;

    // Load the Ramp configuration script
    const configScript = document.createElement('script');
    configScript.src = `https://cdn.intergient.com/${PUB_ID}/${WEBSITE_ID}/ramp.js`;
    document.body.appendChild(configScript);

    // Breakpoints
    const onResize = () => {
      if (resizeDebounceRef.current) {
        clearTimeout(resizeDebounceRef.current);
      }

      resizeDebounceRef.current = setTimeout(() => {
        if (window.innerHeight <= 928) {
          window.ramp?.setPath && window.ramp?.setPath('small-screen');
          console.log('[PLAYWIRE] Detected small screen');
        }
      }, 200) as any;
    };
    window.addEventListener('resize', onResize);

    configScript.onload = () => {
      // Handle pre-content video player
      window.ramp.onPlayerReady = () => {
        if (window.__playwireBoltReady) return;
        window.__playwireBoltReady = true;

        window.Bolt.on('precontent_ad_video', window.Bolt.BOLT_AD_REQUEST_START, () => {
          playwireEvents.trigger('precontentRequestStart');
        });
        window.Bolt.on('precontent_ad_video', window.Bolt.BOLT_AD_COMPLETE, () => {
          playwireEvents.trigger('precontentComplete');
        });
        window.Bolt.on('precontent_ad_video', window.Bolt.BOLT_AD_ERROR, () => {
          playwireEvents.trigger('precontentComplete');
        });

        console.log('[PLAYWIRE] Bolt Player Ready');
      };
      
      // Handle static ad units
      (async () => {
        while (
          !window.ramp?.addUnits || 
          !window.ramp?.destroyUnits ||
          !window.ramp?.displayUnits ||
          !window.PageOS?.session?.newPageView
        ) {
          await new Promise((resolve) => setTimeout(resolve, 100));
        }

        window.__playwireRampReady = true;

        if (window.innerHeight <= 928) {
          window.ramp.setPath('small-screen');
          console.log('[PLAYWIRE] Detected small screen');
        }

        console.log('[PLAYWIRE] Ramp Ready');
      })();
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ router.asPath ]);

  return (<></>);
};

/* #endregion */
