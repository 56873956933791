import AuthModel from '@/lib/drupal/models/Auth';
import axios from 'axios';

const instance = axios.create();

// Defaults
instance.defaults.baseURL = process.env.NEXT_PUBLIC_DRUPAL_API_URL;
instance.defaults.withCredentials = true;
instance.defaults.timeout = 30000; // wait for 30s on a timeout

instance.interceptors.request.use(async (response) => {
  if (typeof window === 'undefined') {
    return response;
  }

  const result = localStorage.getItem('.storage.sessionStatus');
  if (result === null) {
    return response;
  }

  try {
    const authState = JSON.parse(result as string);
    const oneHour = 60 * 60 * 1000;
    if (
      authState.loginStatus && 
      (Date.now() - authState.lastVerifiedAt) > oneHour
    ) {
      const status = await AuthModel.status();
      if (!status.login_status) {
        window.location.reload();
        return;
      }
    }
  } catch {}

  return response;
});

export default instance;

export const jsonApiHeaders = {
  'Accept': 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json'
};
